document.addEventListener("DOMContentLoaded", function() {

    let map = L.map('map', {
        gestureHandling: L.Browser.mobile
    });
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

    let coordinates = [49.8946, 7.9007];

    let importantMarker = L.marker(coordinates).addTo(map).bindPopup('Langenlonsheim');
    map.setView(coordinates, 13);
    importantMarker.openPopup();

    L.Control.Center = L.Control.extend({
        onAdd: function(map) {
            let leafletBars = document.getElementsByClassName("leaflet-bar");
            if (leafletBars.length == 1) {
                let leafletBar = leafletBars[0];
                var anchor = L.DomUtil.create('a', 'leaflet-control-zoom-out d-flex justify-content-center bg-white');
                anchor.href = "#";
                anchor.title = "Center";
                anchor.innerHTML = '<span class="material-icons-outlined d-flex my-auto">crop_free</span>';
                anchor.addEventListener('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    map.setView(coordinates, 12);
                    importantMarker.openPopup();
                    return false;
                });
                leafletBar.appendChild(anchor);
            }
        }
    });

    (new L.Control.Center({})).addTo(map);
});